<template>
  <Layout ref="layout">
  <ModalAddMember :priceSeat="this.pricePerSeat"/>
  <ModalEditMember :member="this.seat" :currentEnterprise="this.currentEnterprise"/>
  <ModalRemoveMember :member="this.seat" :companyName="this.companyName"/>
    <div v-if="isSuspend != true" class="head-enterprise-page px-4 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn-add-member-enterprise" @click="addSeat()">Add Seat</button>
        <div class="input-search-member">
            <img src="@/assets/images/icon/search-black.svg" alt="" style="height: 16px; width: 16px;">
            <input v-model="search" type="text" placeholder="Search">
        </div>
      </div>
    </div>
    <div v-if="isSuspend != true" class="list-enterprise-member px-4">
      <table class="table-enterprise">
        <thead>
          <th>Name</th>
          <th>Email</th>
          <th>Used Storage</th>
          <th></th>
        </thead>
        <tbody v-if="filteredMember.length > 0">
          <tr v-for="member in filteredMember" :key="member._id">
            <td>
              <div class="d-flex align-items-center">
                <img v-if="member.name === '-'" src="@/assets/images/icon/pending-user.svg" style="height: 32px; width: 32px;" alt="">
                <div v-else class="avatar-xs">
                  <span class="avatar-title-api rounded-circle">{{member.name.charAt(0).toUpperCase()}}</span>
                </div>
                <span class="ml-2 enterprise-member-list-text">
                  {{member.name}}
                </span>
              </div>
            </td>
            <td>
              <span class="enterprise-member-list-text">
                {{member.email}}
              </span>
            </td>
            <td>
              <span class="enterprise-member-list-text">
                {{member.storage}}
              </span>
            </td>
            <td>
              <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/images/icon/btn-more.svg" alt="">
                  </div>
                </template>
                <b-dropdown-item>
                  <div class="d-flex justify-content-start ml-2" @click="editSeat(member)">
                    <img src="@/assets/images/icon/fi-rr-edit.svg" alt="">
                    <span class="enterprise-member-list-text ml-3">Edit</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="member.email != myEnterprise.email" @click="removeSeat(member)">
                  <!-- <div class="d-flex justify-content-start ml-2" v-b-modal.modal-remove-member-enterprise> -->
                  <div class="d-flex justify-content-start ml-2">
                    <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt="">
                    <span class="enterprise-member-list-text ml-3">Remove</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="filteredMember.length <= 0 && search != ''" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">Member not found!</span>
      </div>
      <div v-else-if="filteredMember.length <= 0" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">No enterprise member</span>
        <span class="empty-word-sub-title">The member details you add to the enterprise will be shown here.</span>
      </div>
    </div>
    <SuspendPage v-if="isSuspend == true"/>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import ModalAddMember from './modalAddMember.vue'
import ModalEditMember from './modalEditMember.vue'
import ModalRemoveMember from './modalRemoveMember.vue'
import SuspendPage from './suspendEnterprise.vue'

export default {
    data() {
      return {
        // members: [],
        members: [
          {
            name: 'Hari Goodwin',
            email: 'Harigoodwin@gmail.com',
            usedStorage: '750 / 800GB',
            id: 1
          },
          {
            name: 'Sumayyah Prince',
            email: 'Sumayyahprince@gmail.com',
            usedStorage: '50 / 300GB',
            id: 2
          },
          {
            name: 'Carter Passaquindici',
            email: 'Carterpassaquindici@gmail.com',
            usedStorage: '170 / 500GB',
            id: 3
          },
          {
            name: 'Livia Bator',
            email: 'Liviabator@gmail.com',
            usedStorage: '250 / 500GB',
            id: 4
          },
          {
            name: 'Aspen Torff',
            email: 'Aspentorff@gmail.com',
            usedStorage: '300 / 500GB',
            id: 5
          },
          {
            name: '-',
            email: 'Aspentorff@gmail.com',
            usedStorage: '300 / 500GB',
            id: 6
          },
          {
            name: 'Torff Bills',
            email: 'Torffbills@gmail.com',
            usedStorage: '230 / 500GB',
            id: 7
          },
          {
            name: 'Bjorn Latom',
            email: 'Bjornlatom@gmail.com',
            usedStorage: '100 / 500GB',
            id: 8
          }
        ],
        search: '',
        seat: null,
        companyName: null,
        currentEnterprise: null,
        pricePerSeat: null
      }
    },
    components: {
        Layout,
        ModalAddMember,
        ModalEditMember,
        ModalRemoveMember,
        SuspendPage
    },
    mounted(){
      var payload = {
        used: 'ASC',
        search: ''
      }
      if(this.$store.getters['state/state'] != 'enterprise'){
        this.$router.push({name:'home'}).catch({})
      } else {
        this.$store.dispatch('enterprise/getListSeatEnterprise', payload)
        this.$store.dispatch('enterprise/getPlanEnterprise')

      }
    },
    computed: {
      isSuspend(){
        return this.$store.getters['enterprise/statusSuspend']
      },
      myEnterprise(){
        return this.$store.getters['enterprise/myEnterprise']
      },
      enterprisePlan(){
        return this.$store.getters['enterprise/planEnterprise']
      },
      listSeatEnterprise() {
        return this.$store.getters['enterprise/listSeatEnterprise']
      },
      filteredMember() {
          return this.listSeatEnterprise.filter((member) => {
              if(member.name.toLocaleLowerCase().match(this.search.toLocaleLowerCase()) || member.email.toLocaleLowerCase().match(this.search.toLocaleLowerCase())) {
                  return member
              } 
          })
      }
    },
    methods: {
      addSeat(){
        this.pricePerSeat = 0
        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'modal-add-member-enterprise')
        })
      },
      editSeat(member){
        this.seat = member
        this.currentEnterprise = this.myEnterprise
        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'modal-edit-member-enterprise')
        })
      },
      removeSeat(member){
        this.seat = member
        this.companyName = this.myEnterprise.company_name
        
        this.$nextTick(() => {
          this.$root.$emit('bv::show::modal', 'modal-remove-member-enterprise')
        })
      }
    }
}
</script>

<style>

</style>